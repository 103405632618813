$pn-font-path: "../fonts" !default;
@font-face {
  font-family: "Montserrat";
  src: url("#{$pn-font-path}/Montserrat-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@page {
  margin: 0;
}

.testoSfondoBlu {
  padding: 4px 8px 4px 8px;
  font-weight: bold;
  color: yellow;
  background-color: #0e1a70;
}

body {
  font-family: "Montserrat" !important;
}
$menu-color: #ef5350;
$menu--achat-color: #00aeef;
$menu--achat-color-first: #72cbf2;
$menu--achat-color-second: #4eb4e6;
$menu--achat-color-third: #2696d3;

$menu--vente-color: #108d9a;
$menu--vente-color-first: #58c5cc;
$menu--vente-color-second: #19b4bb;
$menu--vente-color-third: #086d72;

$menu--users-color: #5c3494;
$menu--users-color-first: #846eb1;
$menu--users-color-second: #694a9e;
$menu--users-color-third: #432c79;

$menu--grey-color: #eeedee;
$menu--black-color: #231f20;

* {
  margin: 0;
}
#modal-min-height .ant-modal-body {
  min-height: 52vh !important;
}
#gauge-chart5 text {
  font-size: 16px !important;
  margin-top: 10%;
}

body {
  overflow: hidden;
}
.auto-tecdoc-img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.auto-tecdoc-img-gray {
  width: 20px;
  height: 20px;
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1);
  cursor: pointer;
}

.modal-large {
  @media (max-width: 768px) {
    width: 100% !important;
  }
  width: 80% !important;
}
.modal-small {
  width: 50% !important;
}
.ant-modal-wrap {
  overflow: hidden !important;
}
.ant-form label {
  @media (max-width: 768px) {
    font-size: 10px !important;
  }
}
.logo {
  margin: 16px;
}
.ant-layout-header .ant-menu {
  margin-top: 1vh;
  line-height: initial !important;
}

.ant-menu-horizontal .logo {
  height: 44px;
  margin: 10px;
  display: inline-block;
  width: auto;
  vertical-align: top;
}

.auto-border-red {
  border: 1px red solid;
}

.selected-line-item-achat {
  background-color: $menu--achat-color-first !important;
}

.selected-line-item-vente {
  background-color: $menu--vente-color-first !important;
}
.smtv-modal.ant-modal {
  top: 0;

  .ant-modal-content {
    margin: 24px auto;
    max-height: calc(100vh - 48px);
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }

  .ant-modal-body {
    flex: 1;
    overflow-y: auto;
  }
}
.cls-1 {
  fill: $menu--vente-color-second !important;
}

.site-page-header-ghost-wrapper {
  border: 0px solid transparent !important;
  padding: 0vh !important;
}

#vente .auto--overflow {
  max-height: 70vh;
  overflow-y: auto;
  scrollbar-width: 3px;
}
#vente .auto--overflow::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#vente .auto--overflow::-webkit-scrollbar {
  width: 0.3rem;
  background-color: #f5f5f5;
}

#vente *::-webkit-scrollbar-track {
  background-color: $menu--vente-color-second;
}

#vente *::-webkit-scrollbar {
  width: 10px;
  background-color: $menu--vente-color-second;
}

#vente *::-webkit-scrollbar-thumb {
  background-color: $menu--vente-color-second;
}

/*----- achat*/

#achat .auto--overflow {
  max-height: 75vh;
  overflow-y: auto;
  scrollbar-width: 3px;
}
#achat .auto--overflow::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#achat .auto--overflow::-webkit-scrollbar {
  width: 0.3rem;
  background-color: #f5f5f5;
}

#achat *::-webkit-scrollbar-track {
  background-color: $menu--achat-color;
}

#achat *::-webkit-scrollbar {
  width: 10px;
  background-color: $menu--achat-color;
}

#achat *::-webkit-scrollbar-thumb {
  background-color: $menu--achat-color;
}

.auto--overflow::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.ant-table {
  background-color: rgba(255, 255, 255, 0.95) !important;
  color: #231f20 !important;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 0.625em 0.625em !important;
}
.auto--background-rembourssement {
  background-color: #e6d8e0;
}
.auto--notif {
  font-size: 2vh;
  margin-top: 2vh;
}
.inStock {
  background-color: #dff0d8;
}

::-webkit-scrollbar {
  width: 10px;
}
.ant-descriptions-item-label {
  font-size: 16px !important;
  font-weight: bold !important;
}

.info-box {
  border: #bfbfbf 1px solid;
  padding: 5px 10px;
  border-radius: 10px;
}
.info-box:first-of-type {
  margin-right: 5px;
}
.info-box:last-of-type {
  margin-left: 5px;
}
.info-box > div,
.info-box h4 {
  border-bottom: #bfbfbf 2px solid;
  margin: 0;
  line-height: 30px;
}
.info-box h4 {
  color: #bfbfbf;
  padding-bottom: 5px;
}
.info-box span {
  font-weight: bold;
}
.info-box > div:last-of-type {
  border-bottom: none;
}
.auto--color-danger {
  font-weight: bold;
  font-size: 1rem;
  color: #ef5350 !important;
}
.auto--color-warning {
  font-weight: bold;
  font-size: 1rem;
  color: darkorange !important;
}
.auto--color-success {
  font-weight: bold;
  font-size: 1rem;
  color: #1b9e4d !important;
}
.auto-bon-retour-background {
  background-color: #e6d8e0;
}

.ant-layout-content {
  background-color: #ecedf0a1;
  background-image: url("./images/background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-blend-mode: saturation;
}
.auto--custom-header {
  background-color: rgba(255, 255, 255, 0.95);
  padding: 0.5vh;
}
.ant-table-thead > tr > th {
  background-color: #f0f0f0 !important;
  font-size: 1em;
  font-weight: bold !important;
}

//menu
.ant-layout-header {
  background-color: #fff !important;
  height: 35px !important;
  line-height: 35px !important;
}
.ant-menu {
  font-size: 1.25em !important;
  text-transform: uppercase !important;
}
.ant-menu-item {
  min-width: 15vh;
  max-height: 5vh;
  border: 2px solid $menu--users-color-third;
  margin-bottom: 5px !important;
  background-color: white;
  text-align: center;
}

.ant-menu-submenu {
  background-color: white;
  min-width: 15vh;
  text-align: center;
}

.auto--menu-vente-background {
  background-color: $menu--vente-color !important;
}
.auto--menu-achat-background {
  background-color: $menu--achat-color;
}

.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  vertical-align: middle !important;
}

.auto--menu-achat-background :hover {
  color: $menu--achat-color !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  border-bottom: 0px solid transparent !important;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: rgba(0, 0, 0, 0.85) !important;
}
.auto--menu-vente-background a {
  color: #fff !important;
}

.auto--new-menu-achat {
  margin-top: 0.5vh;
  background-color: $menu--achat-color;
  color: #fff;
  font-size: 1em !important;
}

.auto--new-menu-vente {
  margin-top: 0.5vh;

  background-color: $menu--vente-color;
  color: #fff;
  font-size: 1em !important;
}

.auto--new-menu-users {
  margin-top: 0.5vh;

  background-color: $menu--users-color;
  color: #fff;
  font-size: 1em !important;
}

.auto--button-menu {
  text-align: center !important;
  margin-top: 0.5vh;
  max-height: 6vh;
  cursor: pointer;
  color: white !important;
}
.auto--button-menu span {
  color: white !important;
}
.auto--img-button:hover {
  fill: red;
}
.auto--new-menu-achat .auto--button-menu:hover {
  background-color: $menu--achat-color-third;
}
.auto--new-menu-achat .auto--button-menu-selecetd {
  background-color: $menu--achat-color-third !important;
}
.auto--new-menu-vente .auto--button-menu:hover {
  background-color: $menu--vente-color-third;
}
.auto--new-menu-users .auto--button-menu:hover {
  background-color: $menu--users-color-third !important;
}
.auto--new-menu-users .auto--button-menu-selecetd {
  background-color: $menu--users-color-third !important;
}
.auto--new-menu-vente .auto--button-menu-selecetd {
  background-color: $menu--vente-color-third !important;
}
.auto--img-button {
  margin-top: 0.5vh;
  height: 50%;
}
.auto--icons-right {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;

  align-items: center;
  background-color: $menu--grey-color;
}
.ant-input-search input {
  background-color: #f0f0f0 !important;
}
.ant-input-search-button {
  background-color: #f0f0f0 !important;
  color: #58595b !important;
  border-color: #d9d9d9 !important;
}
.auto--background-grey {
  background-color: #f0f0f0;
}

#vente .auto--background-vente-selected {
  background-color: $menu--vente-color-second !important;
  color: #fff !important;
}

#achat .auto--background-achat-selected {
  background-color: $menu--achat-color-second !important;
  color: #fff !important;
}

#vente .auto--switch-color {
  color: $menu--vente-color-second !important;
}
.auto--icon-list {
  width: 100%;
}
#vente path {
  //fill: $menu--vente-color;
}
#vente .auto--color-white path {
  fill: white;
}
.auto--color-white {
  color: white !important;
}
.auto--circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #bcd6ff;
  text-align: center;
}

.ant-select-dropdown-placement-bottomLeft {
  min-width: 220px !important;
}
.app--select-designation .ant-select-dropdown-placement-bottomLeft {
  min-width: 600px !important;
}
.auto--circle {
  width: 150px;
  height: 150px;
  line-height: 150px;
  border-radius: 50%;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
}
.auto--client-name {
  color: black;
  padding: 0.5vh;
  margin-left: 3vh;
  background-color: #fafafa;
}
.auto--pointer {
  cursor: pointer;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #949494 !important;
}

.auto--border-red {
  border: 3px red solid;
}

.auto--objectif-pass {
  background-color: rgb(235, 189, 147);
}

.auto--objectif-done {
  background-color: rgb(203, 214, 181);
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}

.app--graph-tooltip {
  margin: 0;
  line-height: 5px;
  padding: 1rem;
  border: 1px solid #f5f5f5;
  background-color: rgba(255, 255, 255, 255);
  box-shadow: 0px 6px 30px rgba(43, 43, 43, 0.13);

  padding-bottom: 0%;
}
